const initialState = {
  isOpen: false,
  inventoryForWorkOrder: {},
  openSideBarRouteBase: false,
  afterDraftOpenWorkorderForm: false,
  commonDrawer: {},
  sparePartForm: {
    id: "",
    show: false,
  },
};

export const drawer = (state = initialState, action) => {
  switch (action.type) {
    case "TOGGLE_DRAWER": {
      return {
        ...state,
        isOpen: action.payload,
      };
    }

    case "TOGGLE_SIDE_BAR_ROUTE_BASE": {
      return {
        ...state,
        openSideBarRouteBase: action.payload,
      };
    }
    case "AFTER_DRAFT_OPEN_WORKORDER_FORM": {
      return {
        ...state,
        afterDraftOpenWorkorderForm: action.payload,
      };
    }

    case "COMMON_DRAWER": {
      return {
        ...state,
        commonDrawer: action.payload,
      };
    }
    case "TOGGLE_SPARE_PART_FORM": {
      return {
        ...state,
        sparePartForm: {
          id: action.payload.id || "",
          show: action.payload.show,
        },
      };
    }

    default: {
      return state;
    }
  }
};
