/* eslint-disable multiline-ternary */
import React, { Fragment } from "react";
import { Progress } from "reactstrap";
import "./style.scss";

function UploadProgressBar({ uploadProgress, className, isError, show }) {
  if (show)
    return (
      <div className="customProgressBarContainer">
        <Progress
          bar
          animated={uploadProgress !== 100}
          color={`${isError ? "danger" : uploadProgress <= 99 ? "warning" : uploadProgress === 100 ? "success" : ""}`}
          className={`${className} customProgressBarContainer--bar`}
          value={uploadProgress || (isError && 100)}
        ></Progress>
        <span className="customProgressBarContainer--progress"> {uploadProgress}%</span>
      </div>
    );
  return <div />;
}

export default UploadProgressBar;
