import React from "react";
import { useContext } from "react";
import { useDispatch } from "react-redux";
import { changeDirection } from "redux/actions/customizer";
import { directions, locales } from "utility/config";
import { IntlContext } from "utility/context/Internationalization";
import { ContextLayout } from "utility/context/Layout";
import "./style.scss";

const LanguageNavigation = () => {
  const {
    state: { locale },
    switchLanguage,
  } = useContext(IntlContext);

  const layoutContext = useContext(ContextLayout);
  const dispatch = useDispatch();
  const toggleLangue = (lang) => {
    const direction = lang === locales.EN ? directions.LTR : directions.RTL;
    const language = lang === locales.EN ? locales.EN : locales.AR;
    switchLanguage(language, direction);
    dispatch(changeDirection(direction));
    layoutContext.switchDir(direction);
  };
  return (
    <div className="LanguageNavigation">
      <strong
        onClick={() => toggleLangue(locales.EN)}
        className={`LanguageNavigation--text LanguageNavigation--toggler ${locale === locales.EN ? "active" : ""}`}
      >
        English
      </strong>
      <strong className="LanguageNavigation--text">|</strong>
      <strong
        onClick={() => toggleLangue(locales.AR)}
        className={`LanguageNavigation--text LanguageNavigation--toggler ${locale === locales.AR ? "active" : ""}`}
      >
        العربية
      </strong>
    </div>
  );
};

export default LanguageNavigation;
