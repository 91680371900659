import { sparePartIcon } from "assets/icons/svgIcons";

export default [
  {
    id: "spareParts",
    title: "Spare parts",
    icon: sparePartIcon,
    type: "item",
    navLink: "/spareParts",
    pageTitle: "Spare Parts",
    permissions: [
      "super_admin",
      "site_admin",
      "organization_admin",
      "engineer",
      "hmc_supervisor",
      "hmc_technician",
      "qa",
      "helpdesk",
      "call_center",
    ],
  },
];
