const initialState = {
  isInstallationForm: false,
  isLocationForm: false,
  isClinicalHeadForm: false,
  isBioMedEngForm: false,
  bulkSelect: { inventoryIds: [], groupId: "" },
};
export const assetAcceptance = (state = initialState, action) => {
  switch (action.type) {
    case "TOGGLE_INS_FORM": {
      return {
        ...state,
        isInstallationForm: action.payload,
      };
    }
    case "TOGGLE_LOCATION_FORM": {
      return {
        ...state,
        isLocationForm: action.payload,
      };
    }
    case "TOGGLE_CLINICAL_HEAD_FORM": {
      return {
        ...state,
        isClinicalHeadForm: action.payload,
      };
    }
    case "TOGGLE_BIOMED_ENG_FORM": {
      return {
        ...state,
        isBioMedEngForm: action.payload,
      };
    }
    case "BULK_SELECT":{
    return {
      ...state,
      bulkSelect: action.payload,
    };
  }
    default: {
      return state;
    }
  }
};
