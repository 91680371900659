// ** Navigation sections imports

import hmcs from "./hmcs";
import users from "./users";
import sites from "./sites";
import regions from "./regions";
import dashboard from "./dashboard";
import spareParts from "./spareParts";
import workOrders from "./workOrders";
import equipments from "./equipments";
import inventories from "./inventories";
import manufacturers from "./manufacturers";
import siteDepartments from "./siteDepartments";
import organizationAdmin from "./organizationAdmin";
import inventoryPlanning from "./inventoryPlanning";
import maintenanceDepartment from "./maintenanceDepartment";
import acceptance from "./assetAcceptance";


let navRoutes = [
  ...dashboard,
  ...workOrders,
  ...users,
  ...inventories,
  ...spareParts,
  ...inventoryPlanning,
  ...equipments,
  ...regions,
  ...sites,
  ...siteDepartments,
  ...maintenanceDepartment,
  ...hmcs,
  ...manufacturers,
  ...organizationAdmin,
  ...acceptance,
];

// if (env.PROJECT === projects.ASCEND_SERVICES) {
//   navRoutes = navRoutes.filter((item) => !item.isWorkOrder);
//   navRoutes = navRoutes.map((item) => {
//     if (item.children)
//       item = {
//         ...item,
//         children: item?.children.filter((cItem) => !cItem.isWorkOrder),
//       };
//     return item;
//   });
// }
// ** Merge & Export
export default navRoutes;
