require("dotenv").config();

export const env = {
  APP_VERSION: process.env.REACT_APP_VERSION || 1.0,

  BACKEND_VERSION: process.env.REACT_APP_BACKEND_VERSION || "v1",
  API_LAB: process.env.REACT_APP_API_LAB || "https://api.com.sa",
  HOST_URL: process.env.REACT_APP_HOST_URL || "http://localhost:3000",

  BUGSNAG_KEY: process.env.REACT_APP_BUGSNAG_KEY || "abcd1234",

  ENV: process.env.REACT_APP_ENV || "develop",

  PASS_ENCRYPT_KEY: process.env.REACT_APP_PASS_ENCRYPT_KEY || "abcd1234",

  AWS_ACCESS_KEY_ID: process.env.REACT_APP_AWS_ACCESS_KEY_ID || "abcd1234",
  AWS_BUCKET: process.env.REACT_APP_AWS_BUCKET || "my-bucket",
  AWS_DEFAULT_REGION: process.env.REACT_APP_AWS_DEFAULT_REGION || "west",
  AWS_URL: process.env.REACT_APP_AWS_URL || "https://ascend.com.sa",
  AWS_SECRET_ACCESS_KEY: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY || "abcd1234",
  AWS_BUCKET_URL: process.env.REACT_APP_AWS_BUCKET_URL || "https://ascend.com.sa",

  SUPPORT_API_BASEURL: process.env.REACT_APP_SUPPORT_API_BASEURL || "https://dev-nhcc.kakashi.app/api/v2/",
  SUPPORT_PASS_ENCRYPT_KEY: process.env.REACT_APP_SUPPORT_PASS_ENCRYPT_KEY || "Finished",
  SUPPORT_TICKET_CREATE_KEY: process.env.REACT_APP_SUPPORT_TICKET_CREATE_KEY || "testingKey",
  SUPPORT_EMAIL: process.env.REACT_APP_SUPPORT_EMAIL || "masub.imtiaz@ascend.com.sa",
  SUPPORT_PASSWORD: process.env.REACT_APP_SUPPORT_PASSWORD || "1234567",
  SUPPORT_ORGANIZATION_ID: process.env.REACT_APP_SUPPORT_ORGANIZATION_ID || "abcda123982173",
  SUPPORT_TEAM_ID: process.env.REACT_APP_SUPPORT_TEAM_ID || "7429dhn12321988dd921",
  SUPPORT_SUB_TEAM_ID: process.env.REACT_APP_SUPPORT_SUB_TEAM_ID || "abcd12983021ds21ad2sa123ud03ue0",

  INVENTORY_EXPORT_API_BASEURL:
    process.env.REACT_APP_INVENTORY_EXPORT_API_BASEURL || "https://export.com/export-module",

  SPAREPART_EXPORT_API_BASEURL:
    process.env.REACT_APP_SPAREPART_EXPORT_API_BASEURL || "https://export.com/export-module",

  WORKORDER_EXPORT_API_BASEURL:
    process.env.REACT_APP_WORKORDER_EXPORT_API_BASEURL || "https://export.com/export-module",
  WORKORDER_CREATE_URL_PREFIX: "inventories/work-order-create",

  EXPORT_SERVER: process.env.REACT_APP_EXPORT_SERVER || "fakelove",

  EXPORT_KEY: process.env.REACT_APP_EXPORT_KEY || "fakelove",

  GOOGLE_MAPS_JAVASCRIPT_API: process.env.REACT_APP_GOOGLE_MAPS_JAVASCRIPT_API || "acbd213021ieds09ad32iedw",
  PROJECT: process.env.REACT_APP_PROJECT || "apm",
};
export const projects = {
  APM: "apm",
  IMC: "enoviimax",
  MAKESHIFT: "MAKESHIFT",
  ASCEND_SERVICES: "ASCENDSERVICES",
};
export const codeword = {
  REACT_VERSION_MISMATCH: "REACT_VERSION_MISMATCH",
  WORKORDER_EXIST_24H: "WORKORDER_CREATION_WITHIN_TIME_LIMIT",
  ASSET_RETIREMENT_DISABLED: "ASSET_RETIREMENT_DISABLED_WITH_WORKORDERS_EXISTENCE",
};
export const dashboardComarisonID = "22943196-f16a-11ec-8ea0-0242ac120002";
export const userRole = {
  superAdmin: "super_admin",
  siteAdmin: "site_admin",
  organizationAdmin: "organization_admin",
  hmcTechnician: "hmc_technician",
  hmcSupervisor: "hmc_supervisor",
  staff: "staff",
  engineer: "engineer",
  callCenter: "call_center",
  assetSupplier:"asset_supplier",
  hospitalWarehouseManager:"hospital_warehouse_manager",
  hospitalMaintenanceBM:"hospital_maintenance_biomedical_management",
  hospitalBMEngineer:"hospital_biomedical_engineer",
  clinicalDivisionHead:"clinical_division_head",
  hospitalDirector:"hospital_director",
  clusterEquipmentBMEngineer:"cluster_equipment_biomedical_engineer",
  clusterHead: "cluster_head",
  hospitalBMDeptHead: "head_of_hospital_biomedical_department",
  clusterEquipmentDepartment: "cluster_equipment_department",
  clusterContractDepartmentMember: "cluster_contract_department_member",
  clusterContractDepartmentManager: "cluster_contract_department_manager",
  clusterEquipmentCommitteeMember: "cluster_equipment_committee_member",
  clusterEquipmentCommitteeChairman: "cluster_equipment_committee_chairman",
  hospitalMaintenanceContractor: "hospital_maintenance_contractor"
};
export const acUserRole = {
  assetSupplier: "asset_supplier",
  hospitalWarehouseManager: "hospital_warehouse_manager",
  hospitalMaintenanceBM: "hospital_maintenance_biomedical_management",
  hospitalBMEngineer: "hospital_biomedical_engineer",
  clinicalDivisionHead: "clinical_division_head",
  hospitalDirector: "hospital_director",
  clusterEquipmentBMEngineer: "cluster_equipment_biomedical_engineer",
  clusterHead: "cluster_head",
};

export const scrappingUserRole = {
  hospitalBMDeptHead: "head_of_hospital_biomedical_department",
  clusterEquipmentDepartment: "cluster_equipment_department",
  clusterContractDepartmentMember: "cluster_contract_department_member",
  clusterContractDepartmentManager: "cluster_contract_department_manager",
  clusterEquipmentCommitteeMember: "cluster_equipment_committee_member",
  clusterEquipmentCommitteeChairman: "cluster_equipment_committee_chairman",
  hospitalMaintenanceContractor: "hospital_maintenance_contractor"
};

export const site_model = {
  makeShift: "MAKESHIFT",
  permanent: "PERMANENT",
  imc: "IMC",
  ascendServices: "ASCENDSERVICES",
  MakeShiftAndPermanent: ["MAKESHIFT", "PERMANENT"],
};
export const types = {
  medical: "MEDICAL",
  nonmedical: "NONMEDICAL",
  generic: "GENERIC",
};
export const woType = {
  corrective: "corrective",
  preventive: "preventive",
};
export const inventoryStatus = {
  inService: "IN_SERVICE",
  retired: "RETIRED",
};
export const inventoryCurrentStatus = {
  NOT_WORKING: "Not Working",
  WORKING: "Working",
};
export const BackLinks = {
  NHCC: "https://nhcc.ascend.com.sa/",
};
export const directions = {
  LTR: "ltr",
  RTL: "rtl",
};
export const locales = {
  EN: "en",
  AR: "sa",
};

export const classification = {
  medical: "medical",
  nonMedical: "nonmedical",
  generic: "generic",
  all: "all",
};


