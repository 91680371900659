import { Bell, Lock, Power, User } from "react-feather";
import { MdKeyboardArrowDown } from "react-icons/md";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { ProfileIcon, userToggleIcon } from "assets/icons/svgIcons";
import { logout } from "redux/actions/auth/loginActions";
import { getUserRole } from "views/components/generalHelper";
import userAvatar from "assets/img/profile/UserImage/avatar.png";
import Avatar from "views/components/@vuexy/avatar/AvatarComponent";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledTooltip } from "reactstrap";
import { history } from "utility/helper/history";

function UserDropdown() {
  const { app } = useSelector((state) => ({ app: state.customizer }));
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logout());
  };
  let mode = app.customizer.theme;

  if (mode === "light") {
    mode = "dark";
  } else {
    mode = "light";
  }
  return (
    <DropdownMenu>
      <DropdownItem tag="a" onClick={() => history.push("/profile")}>
        <User size={14} className="mr-50" />
        <span className="align-middle">
          <FormattedMessage id="Profile" defaultMessage="Profile" />
        </span>
      </DropdownItem>
      <DropdownItem tag="a" onClick={() => history.push("/security")}>
        <Lock size={14} className="mr-50" />
        <span className="align-middle">
          <FormattedMessage id="Security" defaultMessage="Security" />
        </span>
      </DropdownItem>

      <DropdownItem tag="a" href="#">
        <Bell size={14} className="mr-50" />
        <span className="align-middle">
          <FormattedMessage id="Notification (Coming soon)" defaultMessage="Notification (Coming soon)" />
        </span>
      </DropdownItem>

      <DropdownItem tag="a" onClick={handleLogout}>
        <Power size={14} className="mr-50 text-danger" />
        <span className="align-middle text-danger">
          <FormattedMessage id="Log Out" defaultMessage="Log Out" />
        </span>
      </DropdownItem>
    </DropdownMenu>
  );
}

function NavbarUser(props) {
  const [showDDM, setShowDDM] = useState(false);
  const { user } = useSelector((state) => ({
    user: state?.auth.login?.data,
  }));
  // const hostname = window?.location?.hostname;

  return (
    <div className="navbar-user">
      {/* <div className="navbar-user__user d-none d-sm-flex"> */}
      {/* // ! show avatar in all view */}

      <Dropdown
        direction="up"
        isOpen={showDDM}
        tag="li"
        className="dropdown-user nav-item  list-unstyled"
        toggle={() => setShowDDM(!showDDM)}
      >
        <DropdownToggle tag="a" className="nav-link dropdown-user-link">
          <div className="navbar-user__user d-sm-flex">
            <Avatar className="navbar-user__user--avatar" img={user?.image || userAvatar} status="online" />
            <MdKeyboardArrowDown size={25} className="navbar-profile " />
          </div>
        </DropdownToggle>
        <UserDropdown />
      </Dropdown>
    </div>
  );
}

export default NavbarUser;
