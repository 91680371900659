import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { contactUsIcon, faqIcon, helpIcon, settingIcon, whatsNewIcon } from "assets/icons/svgIcons";

import { BackLinks } from "utility/config";
import { Badge } from "react-bootstrap";

import "./style.scss";
import { UncontrolledTooltip } from "reactstrap";

function SidebarFooter({ isBottomShadow, ...props }) {
  const [isHelpMenu, setHelpMenu] = useState(false);
  const [activeHelpMenu, setActiveHelpMenu] = useState("");

  const toggleHelpMenu = () => setHelpMenu(!isHelpMenu);

  const helpMenuContent = [
    {
      title: "FAQ's",
      id: "faqs",
      icon: faqIcon,
      to: "/faq",
      isComingSoon: true,
    },
    {
      title: "What's New",
      id: "whats-new",
      icon: whatsNewIcon,
      to: "/new",
      isComingSoon: true,
    },
    {
      title: "Contact Us",
      id: "contact-us",
      icon: contactUsIcon,
      to: "/contact-us",
      // link: BackLinks.NHCC,
      onClick: props?.onClick,
    },
  ];

  return (
    <div className={`sidebar__footer  ${isBottomShadow && "sidebar__shadow"}`}>
      {/* // ! TODO : Add Design rules */}
      <ul className="navigation navigation-main navigation-footer">
        <li
          id="support"
          className={`nav-item d-flex justify-content-center align-items-center ${isHelpMenu ? "active" : ""}`}
          onClick={toggleHelpMenu}
          onMouseLeave={() => setHelpMenu(false)}
        >
          <a className="d-flex align-items-center justify-content-start">
            <div className="menu-text">
              {helpIcon}
              <span className="menu-item menu-title"><FormattedMessage id={"Help"} defaultMessage={"Help"} /> </span>
            </div>
          </a>
          <div className={`help-menu ${isHelpMenu ? "open" : ""}`}>
            <ul>
              {helpMenuContent.map((content) => {
                let acnchorProps = {};
                if (content.link) {
                  acnchorProps = {
                    ...acnchorProps,
                    href: content.link,
                    rel: "noopener noreferrer",
                    target: "_blank",
                  };
                }
                if (content.onClick) {
                  acnchorProps = {
                    ...acnchorProps,
                    onClick: content.onClick,
                  };
                }
                return (
                  <li key={content.id} onClick={() => setActiveHelpMenu(content.id)}>
                    <a {...acnchorProps} id={content.id}>
                      <div className={`menu-text ${activeHelpMenu === content.id && "active"}`}>
                        {content.icon}
                        <span className="menu-item menu-title" id={content.id}>
                          {<FormattedMessage id={content.title} defaultMessage={content.title}/> }
                        </span>
                      </div>
                    </a>
                    {content?.isComingSoon && (
                      <UncontrolledTooltip placement="top" target={content.id}>
                        <FormattedMessage id={"Coming Soon"} defaultMessage={"Coming Soon"}/> 
                      </UncontrolledTooltip>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
        </li>
        <li className="nav-item d-flex justify-content-center align-items-center">
          <a className="d-flex align-items-center justify-content-start">
            <div className="menu-text" id="settings-comingSoon">
              {settingIcon}
              <span className="menu-item menu-title"><FormattedMessage id={"Settings"} defaultMessage={"Settings"} /> </span>
            </div>
            <UncontrolledTooltip placement="top" target="settings-comingSoon">
            <FormattedMessage id={"Coming Soon"} defaultMessage={"Coming Soon"}/> 
            </UncontrolledTooltip>
          </a>
        </li>
      </ul>
    </div>
  );
}

function NeedHelp(props) {
  return (
    <div onClick={props.onClick} className="pt-1 sidebar__cursorPointer needHelp  mb-1">
      <span className="text-white pt-1 span-help pr-3" onClick={props.onClick}>
        <img src={helpIcon} alt="help" onClick={props.onClick} className="help-box--icon" />
        <span className="ml-2">
          <FormattedMessage id="Need Help?" defaultMessage="Need Help?" onClick={props.onClick} />
        </span>
      </span>
    </div>
  );
}

export default SidebarFooter;

/*
  import PoweredByLogo from "assets/imgs/poweredBy.svg";
  <NeedHelp {...props} />
  <a
    href="https://ascend.com.sa/"
    target="_blank"
    rel="noopener noreferrer"
  >
    <img
      src={PoweredByLogo}
      alt="logo"
      className="authLayout__footer--logo"
    />
  </a>
*/
